import React, { useState, useEffect } from "react";
import { copyImageToClipboard } from 'copy-image-clipboard';
import { ReactPainter } from 'react-painter';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Button, ClickAwayListener, useMediaQuery, Tooltip } from "@material-ui/core";
import { BrushOutlined, Collections, CreateOutlined, CancelOutlined, ReplayOutlined } from "@material-ui/icons";
import { Lightbox }from "react-modal-image";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import EditImageModal from "../EditImageModal";

const useStyles = makeStyles(theme => ({
	messageMedia: {
		cursor: "pointer",
		objectFit: "cover",
		width: 275,
		height: 225,
		borderRadius: 8,
		transition: "all 0.3s",
	},

	deletedMediaMessage: {
		opacity: 0.7,
		filter: "grayscale(80%)",
	},

	editModal: { display: "none" },

	downloadMedia: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		alignItems: "center",
		justifyContent: "center",
		gap: "3px",
	},

	floatingButton: {
    transition: 'transform 0.3s',
    '&:hover': { transform: 'translateY(-5px)' },
  }
}));

const CustomTooltipStyles = {
	tooltip: { padding: "10px", fontSize: "13px", borderRadius: "8px", boxShadow: "0 20px 80px 0" }
};

const CustomTooltip = withStyles(CustomTooltipStyles)(Tooltip);

const ModalImageCors = ({
	imageUrl, ticketId, setIsLightboxVisible, replyMessage = false, isDeleted = false
}) => {
	// 	***************
	// 	** Variables **
	// 	***************
	const classes = useStyles();
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

	const [blobUrl, setBlobUrl] = useState("");
	const [editImagelModalOpen, setEditImageModalOpen] = useState(false);
	const [fetching, setFetching] = useState(true);
	const [open, setOpen] = useState(false);
	const [showImageEditor, setShowImageEditor] = useState(false);
	const [showLightbox, setShowLightbox] = useState(false);
	


	// 	*****************
	// 	** Use Effects **
	// 	*****************
	useEffect(() => {
		if (!imageUrl) return;

		const fetchImage = async () => {
			const { data, headers } = await api.get(imageUrl, { responseType: "blob", });
			const url = window.URL.createObjectURL( new Blob([data], { type: headers["content-type"] }) );
			setBlobUrl(url);
			setFetching(false);
		};

		fetchImage();
	}, [imageUrl]);



	// 	***************
	// 	** Functions **
	// 	***************
	const handleOpenEditImageModal = () => { setEditImageModalOpen(true); };
	const handleCloseEditImageModal = () => { setEditImageModalOpen(false); };

	const handleTooltipOpen = () => { setOpen(true); };
	const handleTooltipClose = () => { setOpen(false); };
	const handleCopyEditedImage = (editedImageUrl) => { copyImageToClipboard(editedImageUrl); };

	const handleCopyImageToClipboard = () => {
		copyImageToClipboard(imageUrl);
		handleTooltipOpen();
	};

	const handleEditImage = (imageName) => {
		setShowImageEditor(true);
		const drawCanvas = document.getElementById(imageName);
		drawCanvas.style.display = "block";
	};

	const handleCancelImage = (imageName) => {
		setShowImageEditor(false);
		const drawCanvas = document.getElementById(imageName);
		drawCanvas.style.display = "none";
	};

	const handleResetImage = () => {
		setShowImageEditor(false);
		setTimeout(function setShowImageEditorTrue() {setShowImageEditor(true)}, 1);
	}

	const handleOpenLightbox = () => {
		setShowLightbox(true);
		setIsLightboxVisible(true);
	};

	const handleCloseLightbox = () => {
		setTimeout(() => {
			setShowLightbox(false);
			setIsLightboxVisible(false);
		}, 50);
	};

	

	// 	************
	// 	** Return **
	// 	************
	return (
		<>
		<center onClick={handleOpenLightbox}>
			<img
				className={isDeleted ? `${classes.messageMedia} ${classes.deletedMediaMessage}` : classes.messageMedia}
				src={fetching ? imageUrl : blobUrl}
				alt="Message received"
			/>

			{showLightbox && (
				<Lightbox
					smallSrcSet={fetching ? imageUrl : blobUrl}
					medium={fetching ? imageUrl : blobUrl}
					large={fetching ? imageUrl : blobUrl}
					showRotate={true}
					alt={<a href={fetching ? imageUrl : blobUrl} target="_blank" rel="noreferrer">{i18n.t("modalImage.openNewTab")}</a>}
					onClose={handleCloseLightbox}
				/>
			)}			
		</center>
		
		<br />
		
		{!replyMessage && (
			<>
				<EditImageModal 
					open={editImagelModalOpen}
					onClose={handleCloseEditImageModal}
					imageUrl={imageUrl}
					ticketId={ticketId}
				/>

				<ClickAwayListener onClickAway={handleTooltipClose}>
					<div align="center" className={classes.downloadMedia}>
						<CustomTooltip 
							arrow
							title={i18n.t("image.tooltipCopyButton")}
							PopperProps={{ disablePortal: true }}
							onClose={handleTooltipClose}
							open={open}
							disableFocusListener
							disableHoverListener
							disableTouchListener
						>
							<Button
								className={classes.floatingButton}
								startIcon={<Collections />}
								color="inherit"
								variant="outlined"
								onClick={handleCopyImageToClipboard}
							>{i18n.t("image.copyButton")}</Button>
						</CustomTooltip>

						<Button
							className={classes.floatingButton}
							startIcon={<CreateOutlined />}
							color="inherit"
							variant="outlined"
							disabled={isDeleted}
							onClick={ () => { handleEditImage(imageUrl.split('public/')[1].split('.')[0]); }}
						>{i18n.t("image.doodleButton")}</Button>

						<Button
							className={classes.floatingButton}
							startIcon={<BrushOutlined />}
							color="inherit"
							variant="outlined"
							disabled={isDeleted}
							onClick={handleOpenEditImageModal}
						>{i18n.t("image.editButton")}</Button>
					</div>
				</ClickAwayListener>

				<div id={imageUrl.split('public/')[1].split('.')[0]} className={classes.editModal}>
					<br />
					<hr />
					<br />

					{showImageEditor ? <ReactPainter
						width={690}
						height={690}
						image={imageUrl}
						initialColor={localStorage.getItem("editImageColor")}
						render={({ triggerSave, canvas, setColor, imageDownloadUrl, getCanvasProps }) => (
							<div>
								<img 
									id={"editedImageBackup" + imageUrl.split('public/')[1].split('.')[0]}
									src={imageUrl}
									alt="Editing Modal"
									hidden 
								/>

								<center>
									<canvas id={"editedImagePanel" + imageUrl.split('public/')[1].split('.')[0]} {...getCanvasProps()} />
								</center>

								<center>
									<input
										value={localStorage.getItem("editImageColor")}
										type="color"
										onChange={(e) => {
											setColor(e.target.value);
											localStorage.setItem("editImageColor", e.target.value);
										}}
									/>
								</center>
								<br />

								<center className={classes.downloadMedia}>
									<Button
										className={classes.floatingButton}
										startIcon={<CancelOutlined />}
										color="inherit"
										variant="outlined"
										onClick={ () => { handleCancelImage(imageUrl.split('public/')[1].split('.')[0]); }}
									>{i18n.t("image.cancelButton")}</Button>

									<Button
										id={"clearEditedImage" + imageUrl.split('public/')[1].split('.')[0]}
										className={classes.floatingButton}
										startIcon={<ReplayOutlined />}
										color="inherit"
										variant="outlined"
										onClick={() => { handleResetImage(); }}
									>{i18n.t("image.resetButton")}</Button>

									<Button
										id={"copyEditedImage" + imageUrl.split('public/')[1].split('.')[0]}
										className={classes.floatingButton}
										startIcon={<Collections />}
										color="inherit"
										variant="outlined"
										onClick={() => { 
											triggerSave();

											const textArea = document.getElementById("textArea");
											textArea.placeholder = i18n.t("image.success");

											setTimeout(function changeTextAreaPlaceholder() {
												textArea.placeholder = !isSmallScreen 
													? i18n.t("messagesInput.placeholderOpen")
													: i18n.t("messagesInput.placeholderOpenMobile");
											}, 5000);
										}}
									>{i18n.t("image.copyButton")}</Button>

									{imageDownloadUrl ? (handleCopyEditedImage(imageDownloadUrl)) : null}
								</center>
							</div>
						)}
					/> : null}
				</div>
			</>
		)}
		</>
	);
};

export default ModalImageCors;